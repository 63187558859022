/* stylelint-disable custom-property-empty-line-before */
[data-theme='light'] {
	--surface-1: rgb(255 255 255);
	--surface-2: rgb(245 246 252);
	--surface-3: rgb(232 236 251);
	--border: rgb(210 217 238);
	--primary-accent: color-mix(in srgb, var(--primary) 12%, transparent);
	--text-1: rgb(0 0 0);
	--text-2: rgb(13 17 28);
	--text-3: rgb(119 128 160);

	--shadow: color-mix(in srgb, var(--text-1) 2%, transparent);
	--body-bg: radial-gradient(100% 100% at 50% 0, rgb(217 226 255 / 51%) 0, transparent 100%);
	--cards-background: linear-gradient(179.82deg, transparent 0.16%, var(--surface-3) 99.85%);
	--toastify-color-light: var(--surface-2);
}
