.wrapper {
	display: inline-block;
	border-radius: 100%;
	transition: transform ease-in-out 500ms;
}

.walletVisual {
	border-radius: 100%;
	overflow: hidden;
	display: inline-block;
}

.placeholder {
	background-color: var(--color-platinum);
	border-radius: 50%;
	display: inline-block;
}
