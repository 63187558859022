/* stylelint-disable value-keyword-case */
/* stylelint-disable custom-property-empty-line-before */
:root {
	--white: white;
	--black: black;

	--primary: rgb(99 188 6);
	--secondary: rgb(181 208 0);

	--primary-gradient: radial-gradient(
		101.8% 4091.31% at 0% 0%,
		var(--primary) 0%,
		var(--secondary) 100%
	);

	--overlay: rgb(13 17 28 / 60%);

	--box-shadow: color-mix(in srgb, var(--overlay) 6%, transparent) 8px 12px 20px,
		color-mix(in srgb, var(--overlay) 4%, transparent) 4px 6px 12px,
		color-mix(in srgb, var(--overlay) 6%, transparent) 4px 4px 8px;

	/* Following color also used by lightweight-charts, needs to be in hex format */
	--green: #76d191;
	--red: #fc5353;
	--yellow: #f93;

	/* toastify theme */
	--toastify-font-family: Ubuntu;
	--toastify-color-success: var(--green);
	--toastify-color-warning: var(--yellow);
	--toastify-color-error: var(--red);
}
