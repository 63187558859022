/* Chrome, Safari, Edge, Opera */
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
	appearance: none;
	margin: 0;
}

/* Firefox */
.no-arrow[type='number'] {
	appearance: textfield;
}

input[type='file'] {
	display: none;
}

.custom-file-upload {
	border: 1px dashed var(--border);
	border-radius: 16px;
	height: 200px;
	width: 100%;
	padding: 6px 12px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.gOYHMo {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: space-between;
}

.clIMsa {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	padding: 72px 0 5rem;
	-webkit-box-align: center;
	align-items: center;
	flex: 1 1 0%;
}

.cJuMSY {
	position: fixed;
	top: 72px;
	right: 1rem;
	width: 100%;
	z-index: 3;
	max-width: 348px !important;
}

.pay-with {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: space-between;
}

.erfIcs {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 20px;
}

.hJYFVB {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.jeYuAz {
	width: fit-content;
}

.css-x9zcw6 {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 11px;
}

.pYsaH a {
	color: unset;
}

a:any-link {
	cursor: pointer;
}

.smOka {
	position: absolute;
	top: 0;
	left: 0;
	height: 138px;
	width: 138px;
}

.css-17l0moo {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	max-width: 224px;
}

.bXKUaT {
	gap: 16px;
}

.bEqvED {
	height: 40px;
	border-radius: 16px;
	padding: 10px;
	transition: opacity 250ms ease 0s;
}

.jnEFg {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	appearance: none;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	padding: 8px 16px;
	color: white;
	border: 0;
	border-radius: 4px;
	background-color: white;
	width: 125px;
}

button {
	user-select: none;
}

.css-18hn7mq {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 600;
	font-size: 14px;
}

.kIHSLT > * {
	user-select: none;
}

.kVNjZg {
	padding: 20px 8px 0;
	position: relative;
	display: flex;
	flex-direction: column;
}

.GfTH {
	position: relative;
	max-width: 80vw;
	background: var(--surface-1);
	border-radius: 16px;
	border: 1px solid var(--border);
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;
	z-index: 1;
	font-feature-settings: 'ss01', 'ss02', 'cv01', 'cv03';
}

.kmmojd {
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 3rem;
	justify-content: space-evenly;
}

.dIAqzj {
	flex: 1 1 0%;
}

.css-12uvan3 {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 20px;
	text-align: start;
}

.cMHLWt {
	flex: 1 1 0%;
	margin: auto;
}

.css-vurnku {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.fgCeff {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	-webkit-box-pack: end;
	justify-content: flex-end;
}

.jtnClT {
	padding: 0;
	width: fit-content;
	background: none;
	text-decoration: none;
}

.cWDToC {
	color: var(--primary);
}

.imhdhD {
	position: relative;
}

.kHIRPQ {
	border: none;
	background-color: transparent;
	margin: 0;
	padding: 0;
	cursor: pointer;
	outline: none;
}

.hrkQLI {
	padding: 6px 12px;
	border-radius: 16px;
}

.hPGtHV {
	height: 24px;
	width: 24px;
}

.vSeCC {
	position: relative;
	padding: 26px 16px;
}

.dXokMm {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-top: 20px;
	border-top: 1px solid var(--surface-3);
}

.ereGUg {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 24px;
}

.erfjwt {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 12px;
}

.eYHTYx {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	padding-bottom: 20px;
}

.bhoFAK {
	display: flex;
	flex-flow: column;
	position: relative;
	border-radius: 16px;
	background-color: transparent;
	z-index: 1;
	width: 100%;
	transition: height 1s ease 0s;
	will-change: height;
}

.gkamEi {
	width: 48.5%;
}

.kkiXeD {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
}

.hOvXWG {
	position: relative;
	display: flex;
}

.iZhrtN {
	width: 24px;
	height: 24px;
	background: none;
	transition: background-color 250ms ease-in 0s;
	box-shadow: white 0 0 1px;
	border-radius: 50%;
}

.IJysW {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.reOdD {
	margin: 0 0.25rem;
	font-size: 20px;
}

.cDVJfz {
	margin: 0 0.25rem 0 0.35rem;
	height: 35%;
}

.epZvyg {
	border-radius: 16px;
	border: 1px solid var(--surface-1);
	background-color: var(--surface-2);
	width: 100%;
}

.colorrr {
	color: var(--text-1) !important;
}

.jGjrwu {
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 1rem;
}

.ereioh {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 16px;
}

.jgrgoQ {
	border: 1px solid var(--border);
	animation: 0.6s linear 0s 1 normal none running none;
	align-self: center;
}

.cVcAns {
	width: 100%;
}

.gXqkQO {
	display: grid;
	grid-auto-rows: auto;
}

.ddcmlg {
	display: flex;
}

.ixVlAp {
	padding: 4px;
	width: auto;
	font-weight: 500;
	text-align: center;
	border-radius: 6px;
	outline: none;
	border: 1px solid transparent;
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0);
}

.dpNkPS {
	width: 100%;
}

.aQTri {
	background-color: transparent;
}

.eOoGds {
	padding: 12px 8px;
	width: 100%;
	font-weight: 500;
	text-align: center;
	border-radius: 12px;
	outline: none;
	border: 1px solid transparent;
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0);
}

.lbXqUa {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	appearance: none;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	color: white;
	border: 0;
	border-radius: 4px;
	padding: 12px 8px;
}

.eOoGds > * {
	user-select: none;
}

.hJYFVB {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.ezHOjM {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 8px;
	justify-items: flex-start;
}

.gajsee {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 6px;
	justify-items: flex-start;
}

.css-1lohbqv {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 600;
}

.blhgKn {
	color: var(--text-1);
}

.fYKQxG {
	line-height: 16px;
	font-size: 14px;
}

.css-fczr0v {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 12px;
	text-align: left;
}

.BkVYr {
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.isLiqudity {
	opacity: 0.3;
	pointer-events: none;
}

.isLiqudity.token-selected {
	opacity: 1;
	pointer-events: auto;
}

.bEIXES {
	flex-wrap: wrap;
}

.bObhWT {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	gap: 12px;
}

.hXXOVF {
	padding: 12px;
	animation: 0.8s linear 0s 1 normal none running none;
}

.hapmMj {
	border: 1px solid var(--border);
}

.jlQAxw {
	width: 100%;
	padding: 1rem;
	border-radius: 16px;
}

.iHjCXw {
	display: grid;
	grid-auto-rows: auto;
	justify-items: flex-start;
}

.css-9bv76i {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
}

.jgKZAt {
	background-color: transparent;
	font-weight: 500;
	text-align: left;
	width: 100%;
}

.gZlbTK {
	color: var(--text-1);

	/* width: 0px; */
	position: relative;
	font-weight: 400;
	outline: none;
	border: none;
	flex: 1 1 auto;
	background-color: transparent;
	font-size: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
	appearance: textfield;

	/* text-align: right; */
}

.css-2qpl5c {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 12px;
	text-align: left;
}

.dCQVZu {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 8px;
}

.hWKjgZ:disabled {
	opacity: 0.5;
	cursor: auto;
	pointer-events: none;
}

.eKOJak {
	border-radius: 8px;
	padding: 4px;
}

.bdLEKg {
	background-color: var(--surface-2);
	color: var(--text-3);
	font-size: 16px;
	font-weight: 500;
}

.lfsInV {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	appearance: none;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	padding: 8px 16px;
	color: white;
	border: 0;
	border-radius: 4px;
}

.hWKjgZ {
	padding: 16px;
	height: 35px;
	font-weight: 500;
	text-align: center;
	border-radius: 20px;
	outline: none;
	border: 1px solid transparent;
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0);
}

.css-15li2d9 {
	box-sizing: border-box;
	margin: 0;
	font-weight: 500;
	font-size: 12px;
}

.ojMTq {
	color: var(--text-3) !important;
}

.cVcAns {
	width: 100%;
}

.iHjCXw {
	display: grid;
	grid-auto-rows: auto;
	justify-items: flex-start;
}

.css-9bv76i {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
}

.eRovVv {
	color: var(--text-3);
	font-size: 12px;
	font-weight: 500;
}

.dCQVZu {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 8px;
}

.kValYi {
	width: 100%;
	-webkit-box-align: center;
	align-items: center;
}

.hAwhdH {
	display: flex;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.css-2c5569 {
	box-sizing: border-box;
	margin: 20px 0 0;
	min-width: 0;
	font-weight: 500;
	font-size: 20px;
	padding: 10px;
	text-align: center;
}

.css-1lohbqv {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 600;
}

.fdQVur {
	display: flex;
	flex-flow: column;
	position: relative;
	border-radius: 20px;
	background-color: var(--surface-1);
	z-index: 1;
	width: initial;
	transition: height 1s ease 0s;
	will-change: height;
}

.cOqmuC {
	border-radius: 20px;
	border: 1px solid var(--border);
	background-color: var(--surface-1);
	width: initial;
}

.hyQXvD {
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 1rem 1rem 0.75rem;
}

.hmakIi {
	color: green;
	width: 0;
	position: relative;
	font-weight: 400;
	outline: none;
	border: none;
	flex: 1 1 auto;
	background-color: transparent;
	font-size: 28px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
	appearance: textfield;
	text-align: right;
}

.byCAPU {
	filter: none;
	opacity: 1;
	transition: opacity 250ms ease-in-out 0s;
	text-align: left;
}

.jAJJVP {
	background-color: var(--surface-2);
	color: var(--text-3);
	font-size: 16px;
	font-weight: 500;
}

.cCMOgz {
	-webkit-box-align: center;
	align-items: center;
	background-color: var(--surface-3);
	opacity: 1;
	color: var(--text-1);
	cursor: pointer;
	border-radius: 16px;
	outline: none;
	user-select: none;
	border: none;

	/* font-size: 24px; */
	font-weight: 500;
	height: 2.4rem;
	width: initial;
	padding: 0 8px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	margin-left: 12px;
	visibility: visible;
	pointer-events: none;
}

.kjAAwT {
	-webkit-box-pack: end;
	justify-content: flex-end;
	padding: 0 1rem 0.75rem;
	height: 32px;
}

.bmCdZH {
	filter: none;
	opacity: 1;
	transition: opacity 250ms ease-in-out 0s;
}

.cPkaXY {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	gap: 8px;
}

.css-zhpkf8 {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 14px;
}

.fbSdRZ {
	color: var(--text-3);
}

.bndAvc {
	display: inline-block;
	height: inherit;
}

.gHpyEg {
	-webkit-box-align: center;
	align-items: center;
	background-color: var(--surface-2);
	opacity: 1;
	color: var(--text-1);
	cursor: pointer;
	border-radius: 16px;
	outline: none;
	user-select: none;
	border: none;
	font-size: 24px;
	font-weight: 500;
	height: 2.4rem;
	width: initial;
	padding: 0 8px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	margin-left: 12px;
	visibility: visible;
	pointer-events: none;
}

.fCkFnu {
	background-color: var(--primary-accent);
	color: var(--primary);
	font-size: 20px;
	font-weight: 600;
}

.gIjoKy {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	appearance: none;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	color: white;
	border: 0;
	border-radius: 4px;
	padding: 12px;
	background-color: var(--primary);
}

.fNUVbK {
	background-color: transparent;
	inset: 0;
	border-radius: inherit;
	height: 100%;
	position: absolute;
	transition: background-color 150ms ease 0s;
	width: 100%;
}

#range-selector {
	height: 310px;
}

/* CHARTS */

.erfjwt {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 12px;
}

.AKZXT {
	position: relative;
	width: 100%;
	max-height: 200px;
	-webkit-box-pack: center;
	place-content: center;
}

.kLcWec {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 6px;
	position: absolute;
	top: -32px;
	right: 0;
}

.rect {
	pointer-events: all;
	cursor: move;
	x: 46.5;
	y: 0;
	width: 509.5;
	height: 170;
}

.cYrhOe {
	min-width: 100px;
}

@media screen and (width >= 768px) {
	.aQTri {
		width: 23%;
	}
}

@media screen and (width < 768px) {
	.aQTri {
		width: 100%;
	}
}

@media screen and (width <= 768px) {
	.cMHLWt {
		flex: 5;
	}
}

@media screen and (width <= 600px) {
	.GfTH {
		max-width: 420px;
	}
}

@media screen and (width <= 425px) {
	.GfTH {
		max-width: 380px;
	}

	.cYrhOe {
		min-width: 0;
	}
}

@media screen and (width <= 380px) {
	.GfTH {
		max-width: 95vw;
	}
}
