.dKubqp {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
}

.gFAiiM {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	width: 100%;
	height: 100%;
}

.cxkBqB {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	padding: 12px 16px;
}

.bUrEIp {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	padding: 12px;
	width: 100%;
}

.hLDwqe {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	padding: 12px;
}

.eXJtHB {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	appearance: none;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	color: white;
	border: 0px;
	border-radius: 4px;
	width: fit-content;
	padding: 4px 8px;
}

.iYtcfo {
	width: 100%;
	display: flex;
	padding: 0px;
	align-items: flex-start;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.cPCYrp {
	width: 100%;
	display: flex;
	padding: 0px;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.bIFEzi {
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.haLsDq {
	width: fit-content;
}

.UseHi {
	color: var(--text-1);
}

.bRgygV {
	color: var(--text-3);
}

.lfOlIy {
	color: var(--text-1);
	font-family: inherit;
	font-weight: 485;
	font-size: 24px;
	margin: 0px;
}

@media (max-width: 720px) {
	.ebuyDK {
		display: none;
	}
}

@media (max-width: 500px) {
	.fxYEYl {
		display: none;
	}
}

.jDtFNx {
	padding: 6px 8px;
	width: fit-content;
	line-height: 24px;
	font-weight: 535;
	text-align: center;
	border-radius: 12px;
	outline: none;
	border: 1px solid transparent;
	color: var(--text-1);
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0px);
}

.jDtFNx:disabled {
	opacity: 0.5;
	cursor: auto;
	pointer-events: none;
}

.dMIgD {
	padding: 4px 8px;
	width: fit-content;
	line-height: 24px;
	font-weight: 535;
	text-align: center;
	border-radius: 16px;
	outline: none;
	border: 1px solid transparent;
	color: var(--text-1);
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0px);
}

.dMIgD:disabled {
	opacity: 0.5;
	cursor: auto;
	pointer-events: none;
}

.dMIgD > * {
	user-select: none;
}

.iFtjaH {
	width: auto;
	font-size: 16px;
	padding: 6px 8px;
	border-radius: 12px;
}

.eoejgw {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 12px;
}

.cjBIlP {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 8px;
}

.dtsltc {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 8px;
	justify-items: center;
}

.kjyCWx {
	-webkit-box-align: center;
	align-items: center;
	background: var(--surface-2);
	border: unset;
	border-radius: 0.5rem;
	color: var(--text-1);
	display: inline-flex;
	padding: 4px 6px;
	-webkit-box-pack: center;
	justify-content: end;
	font-weight: 535;
}

.hgzrpu {
	display: inline-block;
	height: inherit;
}

.ezWZpM {
	-webkit-box-align: center;
	align-items: center;
	background: transparent;
	border: 1px solid var(--border);
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	outline: none;
	padding: 4px;
	width: fit-content;
}

.cQMWPQ {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.CWDpI {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.IPZOf {
	width: 24px;
	height: 24px;
	background: none;
	transition: background-color 250ms ease-in 0s;
	box-shadow: white 0px 0px 1px;
	border-radius: 50%;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.fYWODX {
	width: 20px;
	height: 20px;
	background: none;
	transition: background-color 250ms ease-in 0s;
	box-shadow: white 0px 0px 1px;
	border-radius: 50%;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.jlVDNA {
	position: relative;
	display: flex;
}

.frINir {
	width: 100%;
	padding: 1rem;
	border-radius: 16px;
}

.fejats {
	width: 100%;
	padding: 12px 16px;
	border-radius: 16px;
}

.ebBnOr {
	width: 100%;
	padding: 12px;
	border-radius: 16px;
}

.eNAPHe {
	border: 1px solid var(--border);
	background-color: var(--surface-2);
}

.iqvqwM {
	background-color: var(--surface-1);
	border: 1px solid var(--border);
}

.iTSkDr {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-left: 16px;
}

.bXMiUP {
	z-index: 1;
}

.hMnjEd {
	position: absolute;
	left: -12px !important;
}

.cTKCLz {
	font-size: 14px;
	display: flex;
	-webkit-box-pack: end;
	justify-content: flex-end;
}

.csBMLU {
	font-weight: 535;
	font-size: 12px;
	line-height: 14px;
	margin-right: 8px;
}

.jNuAZM {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: fit-content;
	padding: 1px;
	background: var(--surface-2);
	border-radius: 8px;
	border: 1px solid var(--border);
	cursor: pointer;
	outline: none;
}

.lclAmt {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	padding: 4px 0.5rem;
	border-radius: 6px;
	-webkit-box-pack: center;
	justify-content: center;
	height: 100%;
	background: var(--surface-1);
	color: var(--text-1);
	font-size: 12px;
	font-weight: 535;
	white-space: nowrap;
}

.lclAmt:hover {
	user-select: initial;
	color: var(--text-3);
}

.gAJfJa {
	padding: 68px 16px 16px;
	min-width: 800px;
	max-width: 960px;
}

@media only screen and (max-width: 768px) {
	.gAJfJa {
		min-width: 100%;
		padding: 16px;
	}
}

@media only screen and (max-width: 640px) {
	.gAJfJa {
		min-width: 100%;
		padding: 16px;
	}
}

.kzEqix {
	font-weight: 535;
	font-size: 14px;
	color: var(--text-3);
}

.dJrcRo {
	display: flex;
	font-size: 16px;
	-webkit-box-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	align-items: center;
}

.kyqFWH {
	color: var(--text-3);
	font-size: 14px;
	text-align: center;
	margin-right: 4px;
	font-weight: 535;
}

.KPclN {
	text-decoration: none;
	color: var(--text-3);
}

.KPclN:hover {
	color: var(--text-1);
	text-decoration: none;
}

.cUfrhq {
	color: var(--text-1);
	margin: 0px 1rem;
}

@media only screen and (max-width: 640px) {
	.iYnZBs {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 16px;
		width: 100%;
	}
}

.gvfQYr {
	width: 50%;
	-webkit-box-pack: end;
	justify-content: flex-end;
}

@media only screen and (max-width: 640px) {
	.gvfQYr {
		width: 100%;
		flex-direction: row;
	}

	.gvfQYr * {
		width: 100%;
	}
}

.flrrNb {
	border-radius: 12px;
	padding: 6px 8px;
	width: fit-content;
	font-size: 16px;
}

@media only screen and (max-width: 768px) {
	.flrrNb {
		width: fit-content;
	}
}

@media only screen and (max-width: 640px) {
	.flrrNb {
		width: fit-content;
	}
}

.gGqapu {
	display: grid;
	grid-template: 'overlap';
	min-height: 400px;
}

.hOKIdu {
	grid-area: overlap;
}

.apbUF {
	grid-area: overlap;
	height: 400px;
	z-index: 1;
}

.bGjgQW {
	margin-right: 10px;
}

.kjbeBO {
	flex-wrap: wrap;
	gap: 8px;
}

/*! CSS Used from: Embedded */
.css-1aulwug {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	font-weight: 485;
}

.css-cbqu6f {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	font-weight: 535;
	font-size: 36px;
}

.css-1sy3474 {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	font-weight: 485;
	display: flex;
}

.css-5dyzfr {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	font-weight: 535;
	font-size: 20px;
	text-align: center;
}

.ennNJZ {
	color: var(--text-1);
}
