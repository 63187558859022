.swap-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	padding: 72px 0 5rem;
	-webkit-box-align: center;
	align-items: center;
	flex: 1 1 0%;
}

.card-bg {
	transition: box-shadow 450ms ease 0s;
}

.card-section {
	position: relative;
	padding: 68px 8px 0;
	max-width: 480px;
	width: 100%;
}

.card-bg {
	position: relative;
	background: var(--surface-1);
	border-radius: 24px;
	border: 1px solid var(--border);
	padding: 12px 8px 8px;
	z-index: 1;
	transition: transform 250ms ease 0s;

	/* pointer-events: none; */
}

#card-top {
	margin-bottom: 1px;
	color: var(--text-3);
	-webkit-box-pack: justify;
	justify-content: space-between;

	/* pointer-events: none; */
}

.card-top-parent {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.card-top-parent .top-btns {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

#btns-top {
	width: fit-content;
	padding: 0 12px;
	gap: 5px;
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

#btns-top .swap-btn {
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.buy {
	display: inline-block;
	height: inherit;
}

.buy #btn-id {
	padding: 16px;
	width: 100%;
	font-weight: 500;
	text-align: center;
	border-radius: 20px;
	outline: none;
	border: 1px solid transparent;
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0);
}

.buy .buy-btn {
	padding: 0;
	width: fit-content;
	background: none;
	text-decoration: none;
	color: var(--text-3);
	gap: 2px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.gear {
}

.gear .gear-btn {
	border: none;
	background-color: transparent;
	margin: 0;
	padding: 0;
	cursor: pointer;
	outline: none;
}

.gear-btn div {
	padding: 6px 12px;
	border-radius: 16px;
}

.gear-btn .gear-icon {
	color: var(--text-3);
	font-size: 24px;
}

/* You Pay */
.you-pay {
	background-color: var(--surface-2);
	border-radius: 16px;
	color: var(--text-3);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	position: relative;
}

.you-pay::before {
	box-sizing: border-box;
	background-size: 100%;
	border-radius: inherit;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	content: '';
	border: 1px solid var(--surface-2);
}

#swap-currency-input {
	display: flex;
	flex-flow: column;
	position: relative;
	border-radius: 20px;
	z-index: 1;
	width: initial;
	transition: height 1s ease 0s;
	will-change: height;
}

#swap-currency-input .input-wrapper {
	min-height: 44px;
	border-radius: 20px;
	width: initial;
}

.input-wrapper .youPay-label {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 14px;
}

.paying-wrapper {
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.paying-wrapper #token-amount {
	filter: none;
	opacity: 1;
	transition: opacity 0.2s ease-in-out 0s;
	text-align: left;
	font-size: 36px;
	line-height: 44px;
	font-variant: small-caps;
}

.paying-wrapper .token-amount-input {
	color: var(--text-1);
	width: 0;
	position: relative;
	font-weight: 400;
	outline: none;
	border: none;
	flex: 1 1 auto;
	background-color: transparent;
	font-size: 28px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
	appearance: textfield;
	text-align: right;
}

.open-currency-btn-top {
	-webkit-box-align: center;
	align-items: center;
	background-color: var(--surface-3);
	opacity: 1;
	box-shadow: none;
	color: var(--text-1);
	cursor: pointer;
	height: unset;
	border-radius: 16px;
	outline: none;
	user-select: none;
	border: none;
	font-size: 24px;
	font-weight: 500;
	width: initial;
	padding: 4px 8px 4px 4px;
	gap: 8px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	visibility: visible;
	pointer-events: auto;
}

.open-currency-btn-top::before {
	background-size: 100%;
	border-radius: inherit;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	z-index: -1;
	pointer-events: none;
}

.open-currency-btn-top .span-one {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
}

.cryptocurrency-wrapper {
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.cryptocurrency-wrapper .image-wrapper {
	position: relative;
	display: flex;
}

.image-wrapper div {
	width: 24px;
	height: 24px;
	background: none;
	transition: background-color 250ms ease-in 0s;
	border-radius: 50%;
}

.image-wrapper div .icon-image {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.cryptocurrency-wrapper .token-name {
	margin: 0 0.25rem;
	font-size: 16px;
	font-weight: 600;
	padding: 2px 4px;
}

.dropdown-icon .fa-drop {
	border: none;
}

.switch-button {
	border-radius: 12px;
	height: 40px;
	width: 40px;
	position: relative;
	margin: -18px auto;
	background-color: var(--surface-2);
	border: 4px solid var(--surface-1);
	z-index: 2;
}

.switch-button .switch-bg {
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.switch-bg .arrow-down {
	color: var(--text-1);
}

/* You Recive */
.you-receive {
	background-color: var(--surface-2);
	border-radius: 16px;
	color: var(--text-3);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	padding: 16px;
	position: relative;
}

.you-receive::before {
	box-sizing: border-box;
	background-size: 100%;
	border-radius: inherit;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	content: '';
	border: 1px solid var(--surface-2);
}

.open-currency-btn-bottom {
	-webkit-box-align: center;
	align-items: center;
	opacity: 1;
	cursor: pointer;
	height: unset;
	border-radius: 16px;
	outline: none;
	user-select: none;
	border: none;
	font-size: 24px;
	font-weight: 500;
	width: initial;
	padding: 6px 6px 6px 8px;
	gap: 8px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	visibility: visible;
}

.open-currency-btn-bottom::before {
	background-size: 100%;
	border-radius: inherit;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	pointer-events: none;
}

.open-currency-btn-bottom .span-two {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
}

.text-wrapper {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.text-wrapper .select-token {
	margin: 0 0.25rem;
	font-size: 16px;
	font-weight: 600;
	padding: 2px 4px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align: left;
}

#connectId {
	background-color: var(--primary-accent);
	color: var(--primary);
	font-size: 20px;
	font-weight: 600;
}

.grid {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 4px;
}

/* ------------------------------
-------Media Query----------- */
@media screen and (width >= 768px) {
	.shade-parent {
		height: 100vh;
	}
}
