.jBBXQD[data-reach-dialog-content] {
	margin: auto;
	box-shadow: var(--box-shadow);
	border: 1px solid color-mix(in srgb, var(--border) 30%, transparent);
	padding: 0;
	width: 50vw;
	overflow: hidden auto;
	max-width: 420px;
	max-height: 75vh;
	min-height: 75vh;
	display: flex;
	border-radius: 20px;
}

.jBBXQD {
	overflow-y: auto;
}

[data-reach-dialog-content] {
	margin: 10vh auto;
	outline: none;
	padding: 2rem;
	width: 50vw;
}

.fUHrnW {
	background-color: var(--surface-1);
	color: var(--text-1);
	width: 100%;
	overflow: hidden;
	flex: 1 1 0%;
	position: relative;
	border-radius: 20px;
	border: 1px solid var(--border);
}

.eOCLUf {
	padding: 20px;
}

.kqzAOQ {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.fqkEGC {
	color: var(--surface-1);
	cursor: pointer;
}

.hxoNas {
	background: url('/public/assets/images/search-icon.svg') 12px center / 20px 20px no-repeat scroll
		var(--surface-1);
	position: relative;
	display: flex;
	padding: 16px 16px 16px 40px;
	height: 40px;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	white-space: nowrap;
	outline: none;
	border-radius: 12px;
	color: var(--text-1);
	border: 1px solid var(--border);
	appearance: none;
	font-size: 16px;
	transition: border 100ms ease 0s;
}

.kqyzGE {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 12px;
}

.bORAza {
	flex-wrap: wrap;
	margin: -4px;
}

.cTSGxd {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	gap: 4px;
}

.bORAza > * {
	margin: 4px !important;
}

.ckpBIe {
	width: 24px;
	height: 24px;
	background: none;
	transition: background-color 250ms ease-in 0s;
	box-shadow: white 0 0 1px;
	border-radius: 50%;
}

.bwVixy {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.css-xy7yfl {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 16px;
}

.css-xy7yfl span .ri-close-line {
	cursor: pointer;
}

.eLvYRk {
	position: relative;
	display: flex;
}

.cbqHzZ {
	width: 100%;
	height: 1px;
	background-color: var(--border);
}

.labelsWrapper {
	flex: 1 1 0%;
	position: relative;
}

/* ch */

.token-data-tabs {
	flex: 1 1 0%;
	position: relative;
}

.token-data-tabs-wrapper {
	position: relative;
	height: 553px;
	width: 100%;
	overflow: auto;
	willchange: transform;
	direction: ltr;
}

.token-data-tabs-wrapper .token- {
	height: 13552px;
	width: 100%;
}

#tokenBtick {
	justify-self: flex-end;
}

.close-modal {
	cursor: pointer;
	font-size: 25px;
}

._1pi21y70 {
	height: 100%;
	scrollbar-color: var(--genie-colors-backgroundOutline) transparent;
	scrollbar-width: thin;
}

#cross-title {
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.frnZMKK {
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.fhPvJeh {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.edPdrxe {
	padding: 4px 20px;
	height: 56px;
	display: grid;
	grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
	gap: 16px;

	/* pointer-events: none; */
	opacity: 1;
	cursor: pointer;
}

.edPdrxe:hover {
	background-color: var(--border);
}

.edPdrxe.active {
	opacity: 0.4;
}

.hJYFVB {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.swap-tick {
	color: var(--primary);
	font-size: 20px;
}

.oJGcu {
	width: 36px;
	height: 36px;
	background: none;
	transition: background-color 250ms ease-in 0s;
	border-radius: 50%;
	overflow: hidden;
}

.jdTKGL {
	display: grid;
	grid-auto-rows: auto;
}

.css-vurnku {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.dmGdpm {
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.wHspX {
	margin-left: 0.3em;
}

.bqwbXT {
	color: var(--text-3);
}

.css-yfjwjl {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 300;
	font-size: 12px;
}

.iCxowP {
	display: flex;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.leSroW {
	width: fit-content;
}

.bZZPOo {
	height: 20px;
	width: 20px;
	margin-left: 4px;
	color: var(--primary);
}

.bPeMEk {
	padding: 4px 20px;
	height: 56px;
	display: grid;
	grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
	gap: 16px;
	cursor: pointer;
	opacity: 1;
}

.gxjzue {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 36px;
	height: 36px;
	border-radius: 50%;
}

.resize-triggers,
.resize-triggers > div,
.contract-trigger::before {
	content: ' ';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	z-index: -1;
}

.resize-triggers {
	animation: 1ms resizeanim;
	visibility: hidden;
	opacity: 0;
}

.resize-triggers > div {
	overflow: auto;
}

#background-radial-gradient {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	width: 200vw;
	height: 200vh;
	transform: translate(-50vw, -100vh);
	z-index: -1;
}

.bmYGet[data-reach-dialog-overlay] {
	z-index: 3000;
	overflow: hidden;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	background-color: var(--overlay);
}

[data-reach-dialog-overlay] {
	inset: 0;
	overflow: auto;
	position: fixed;
}

/* media query */

@media screen and (width <= 720px) {
	.gqQqei {
		display: none;
	}
}

@media screen and (width <= 768px) {
	.jBBXQD[data-reach-dialog-content] {
		width: 65vw;
	}
}

@media screen and (width <= 640px) {
	.jBBXQD[data-reach-dialog-content] {
		width: 90vw;
		max-height: 90vh;
	}

	.token-picker-modal[data-reach-dialog-content] {
		height: 600px;
	}

	.decrypt-modal[data-reach-dialog-content] {
		height: unset;
	}

	.review-transaction[data-reach-dialog-content] {
		height: unset;
	}

	.bmYGet[data-reach-dialog-overlay] {
		align-items: flex-end;
	}
}
