.text-accent {
	color: var(--text-3);
}

.dLsVJq {
	display: flex;
	flex-direction: row;
	height: calc(100% - 16px);
	overflow: hidden;
	position: fixed;
	right: -400px;
	top: 8px;
	transition: right 0.5s;
	z-index: 1030;
}

.dLsVJq.open {
	right: 8px;
}

.close-modal-button {
	display: none !important;
}

.moon,
.sun {
	font-size: 20px;
	color: var(--text-1);
}

.cnmVKz::-webkit-scrollbar {
	background: transparent;
	width: 4px;
	overflow-y: scroll;
}

.cnmVKz::-webkit-scrollbar-thumb {
	background: var(--border);
	border-radius: 8px;
}

.cnmVKz::-webkit-scrollbar-track {
	margin-top: 40px;
	right: 0;
}

.hVzdzS {
	text-decoration: none;
	cursor: pointer;
	height: 100%;
	padding: 14px;
	border-radius: 20px 0 0 20px;
	transition:
		background-color 250ms ease 0s,
		margin 250ms ease 0s;
}

.jEKWjE {
	stroke: var(--text-3);
}

.eTxptr {
	z-index: 1040;
	overflow: hidden;
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: color-mix(in srgb, var(--surface-1) 60%, white);
	opacity: 0;
	pointer-events: none;
}

.hyeENl {
	margin-right: 0;
	height: 100%;
	overflow: hidden;
	border-radius: 12px;
	width: 320px;
	font-size: 16px;
	background-color: var(--surface-1);
	border: 1px solid color-mix(in srgb, var(--border) 10%, transparent);
	box-shadow: var(--box-shadow);
	transition: margin-right 250ms ease 0s;
}

.fijsNj {
	height: 100%;
	scrollbar-gutter: stable;
	overscroll-behavior: contain;
	border-radius: 12px;
}

.kfarYJ {
	width: 100%;
	height: 100%;
}

.jcIclM {
	display: flex;
	flex-flow: column;
	background-color: var(--surface-1);
	width: 100%;
	height: 100%;
	padding: 14px 16px 16px;
	flex: 1 1 0%;
}

.NRTGQ {
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.fsgYBU {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.laNPNA {
	box-sizing: border-box;
	margin: 0 0 16px;
	min-width: 0;
	width: 100%;
}

.css-rjqmed {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.kivXvxb {
	color: var(--text-1);
}

.kIafUC {
	background-color: var(--surface-3);
	transition: width 125ms ease-in-out 0s;
	border-radius: 12px;
	display: flex;
	padding: 0;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	height: 32px;
	width: 32px;
	color: var(--text-2);
	border: none;
	outline: none;
}

.bLwPSk {
	width: 16px;
	height: 16px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.kqzAOQ {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.hmenal {
	display: grid;
	gap: 2px;
	border-radius: 12px;
	overflow: hidden;
}

button {
	user-select: none;
}

.hKmnVf {
	-webkit-box-align: stretch;
	align-items: stretch;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
	position: relative;
	width: 100%;
	background-color: var(--surface-2);
}

.fRrCJL {
	-webkit-box-align: center;
	align-items: center;
	background-color: unset;
	border: none;
	cursor: pointer;
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
	opacity: 1;
	padding: 18px;
	transition: all 125ms ease 0s;
}

.fFbTKE {
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
}

.cyjnfZ {
	display: flex;
	flex-flow: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.cyjnfZ > img,
.cyjnfZ span {
	height: 40px;
	width: 40px;
}

.cyjnfZ img {
	border: 1px solid var(--border);
	border-radius: 12px;
}

.kJBdbk {
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	color: var(--text-2);
	font-size: 16px;
	font-weight: 600;
	padding: 0 8px;
}

.ilYVNX {
	padding: 0 4px;
}

.css-4u0e4f {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 12px;
}

.bftkTM {
	color: var(--text-3);
}

.eBkKRJ {
	font-weight: 600;
	color: var(--text-3);
}

.jnMVFjj {
	text-decoration: none;
	cursor: pointer;
	transition-duration: 125ms;
	color: var(--primary);
	stroke: var(--primary);
	font-weight: 500;
}

.hqtSjX {
	color: var(--text-3);
}

.cnmVKz {
	width: 100%;
	overflow: auto;
	margin-top: 4px;
	padding: 14px 16px 16px;
	height: 100%;
}

.fjOrSN {
	color: var(--text-2);
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	position: relative;
	width: 100%;
	margin-bottom: 20px;
}

.back-arrow {
	font-size: 18px;
	cursor: pointer;
}

.ewaNd {
	text-decoration: none;
	cursor: pointer;
	transition-duration: 125ms;
}

.UbGpC {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.bepFek {
	color: var(--text-3);
	padding-bottom: 24px;
}

.bqhmxH {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 24px;
}

.hJYFVB {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
}

.feIyWM {
	width: 40%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.gxbXPT {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	width: 40%;
}

.css-1aekuku {
	color: var(--text-2);
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 500;
	font-size: 14px;
}

.jGtsjx {
	width: 60%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: end;
	justify-content: flex-end;
}

.foniQS {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	-webkit-box-flex: 1;
	flex-grow: 1;
	width: 60%;
}

.etXiLa {
	border-radius: 16px;
	gap: 4px;
	padding: 4px;
	outline: var(--border) solid 1px;
	outline-offset: -1px;
}

.gurCES {
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	border-radius: 12px;
	padding: 6px;
	color: var(--text-3);
	gap: 4px;
	transition: all 250ms ease 0s;
}

.eqaHKd {
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	border-radius: 12px;
	padding: 6px;
	gap: 4px;
	transition: all 250ms ease 0s;
}

.kHFzEX {
	flex-wrap: wrap;
	cursor: pointer;
}

.kMuqSe {
	width: 100%;
}

.jANAGB {
	-webkit-box-align: center;
	align-items: center;
	background: var(--primary-accent);
	border: 1px solid transparent;
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	outline: none;
	padding: 4px;
	width: fit-content;
}

button {
	user-select: none;
}

.jsjUXs {
	animation: 0.1s ease-in 0s 1 normal none running none;
	background: var(--primary);
	border-radius: 50%;
	height: 24px;
	margin-left: 2.2em;
	width: 24px;
}

.fhPvJh {
	width: 100%;
	display: flex;
	padding: 4px;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
}

.iCxowP {
	display: flex;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;

	/* height: 100%; */
}

.css-mx5ldy {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

.bftkTM {
	color: var(--text-3);
}

.ffxhvk {
	-webkit-box-align: center;
	align-items: center;
	background: transparent;
	border: 1px solid var(--border);
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	outline: none;
	padding: 4px;
	width: fit-content;
}

.bQBOQe {
	animation: 0.1s ease-in 0s 1 normal none running none;
	background: var(--text-3);
	border-radius: 50%;
	height: 24px;
	margin-right: 2.2em;
	width: 24px;
}

.iFDhEy {
	cursor: pointer;
	transition-duration: 125ms;
	flex: 1 1 0%;
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	padding: 12px 0;
	-webkit-box-pack: justify;
	justify-content: space-between;
	text-decoration: none;
	color: var(--surface-1);
}

.css-zhpkf8 {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 14px;
}

.zoyEF {
	width: 100%;
	cursor: pointer;
}

.cSretk {
	display: inline-block;
	height: inherit;
}

.css-zhpkf8 {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 14px;
}

.bqwbXT {
	color: var(--text-3);
}

.xyz1 {
	width: 100%;
	display: flex;
	padding: 4px;
	-webkit-box-align: center;
	align-items: center;
	justify-content: center;
	-webkit-box-pack: start;
}

.xyz12 {
	width: 100%;
	height: 40px;
	display: flex;
	padding: 4px;
	-webkit-box-align: center;
	align-self: flex-start;
	cursor: pointer;
}

@media screen and (width >= 1440px) {
	.hyeEN1 {
		margin-right: 0;
		width: 390px;
	}
}

@media screen and (width <= 320px) {
	.dLsVJq {
		top: 0px;
	}

	.dLsVJq.open {
		right: 0px;
	}

	.close-modal-button {
		display: unset !important;
	}
}
