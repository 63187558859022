.card-box {
	border: 1px solid var(--border);
	border-radius: 16px;
	overflow: hidden;
}

.card-secondary {
	background-color: var(--surface-2);
}

.card-primary {
	background-color: var(--surface-1);
}
