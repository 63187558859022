.timeframe-selector {
	top: 56px;
	left: 0;
	right: auto;
}

.timeframe-selector-anchor {
	background-color: var(--surface-3);
}

.token-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	padding: 72px 0 5rem;
	-webkit-box-align: center;
	align-items: center;
	flex: 1 1 0%;
}

.token-container {
	width: 100%;
	min-width: 320px;
	padding: 68px 12px 0;
}

.token-title-wrapper {
	margin-bottom: 32px;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.token-title-wrapper .token-title {
	display: inline-block;
	height: inherit;
	color: var(--text-1);
}

.token-title p {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 36px;
}

.filter-wrapper {
	display: flex;
	max-width: 1200px;
	margin: 0 auto 20px;
	flex-direction: row;
}

.filter-wrapper .filter-dropdowns {
	display: flex;
	gap: 8px;
	height: 40px;
}

.crypto-currency-drop #crypto-btn {
	min-width: 156px;
}

.crypto-currency-drop button {
	height: 100%;
	color: var(--text-1);
	background-color: var(--surface-3);
	margin: 0;
	padding: 6px 12px 6px 14px;
	border-radius: 12px;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	transition-duration: 125ms;
	border: none;
	outline: none;
	user-select: none;
}

.filter-dropdowns .crypto-currency-drop {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	border: none;
	text-align: left;
}

.crypto-btn-content {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
	border: none;
	font-weight: 600;
	vertical-align: middle;
}

.text-and-img {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
}

.text-and-img img {
	height: 20px;
	width: 20px;
}

.icon-drop {
	padding-top: 1px;
	color: var(--primary);
}

.icon-drop.rotated {
	transform: rotate(180deg);
	transition: transform 0.3s ease;
}

.dropdown-item .item-crypto {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
}

.item-crypto img {
	height: 20px;
	width: 20px;
}

.tick-icon {
	color: var(--primary);
}

/* update 1D */
.token-update {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	border: none;
	text-align: left;
}

.token-update-text {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
	border: none;
	width: 100%;
	vertical-align: middle;
}

.token-update-text .icon-drop {
	padding-top: 1px;
	color: var(--primary);
}

.token-update-text .icon-drop.rotated {
	transform: rotate(180deg);
	transition: transform 0.3s ease;
}

.token-input-wrapper {
	display: flex;
	gap: 8px;
	height: 40px;
}

#tokenInputWrapper {
	margin-left: 8px;
	width: 100%;
}

.token-input-wrapper div {
	display: flex;
	flex: 1 1 0%;
}

.token-input {
	background: url('/public/assets/images/search-icon.svg') 12px center / 20px 20px no-repeat scroll
		var(--surface-2);
	border-radius: 12px;
	border: 1.5px solid var(--border);
	height: 100%;
	width: min(200px, 100%);
	font-size: 14px;
	padding-left: 40px;
	padding-right: 10px;
	color: var(--text-3);
	transition-duration: 125ms;
}

.token-input:focus {
	outline: none;
	border-color: var(--primary);
}

/* header row */
.fTDqns {
	background-color: transparent;
	display: grid;
	font-size: 16px;
	grid-template-columns: 1fr 7fr 4fr 4fr 4fr 4fr 5fr;
	line-height: 24px;
	max-width: 1200px;
	min-width: 390px;
	height: 64px;
	padding: 0 12px;
	transition: background-color 125ms ease 0s;
	width: 100%;
}

#giptel {
	border-bottom: var(--border);
	border-top-color: var(--border);
	border-right-color: var(--border);
	border-left-color: var(--border);
	border-radius: 8px 8px 0 0;
	color: var(--text-3);
	font-size: 14px;
	height: 48px;
	line-height: 16px;
	padding: 0 12px;
	width: 100%;
	-webkit-box-pack: center;
	justify-content: center;
}

.iCcgEc {
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	padding: 12px 8px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	text-decoration: none;
	cursor: pointer;
}

.iCcgEc:hover {
	background-color: var(--surface-3);
}

.chstga {
	flex: 1 1 0%;
	padding: 8px;
	color: var(--text-1);
	border-radius: 8px;
}

/* Table */
.EQdYt {
	display: flex;
	max-width: 1200px;
	margin: 0 auto 20px;
	color: var(--text-3);
	flex-direction: row;
}

.jDpEBx {
	display: flex;
	gap: 8px;
	height: 40px;
}

.hMzHak {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	border: none;
	text-align: left;
}

.kbPnAK {
	min-width: 156px;
}

.VWsYV {
	height: 100%;
	color: var(--surface-1);
	background-color: var(--surface-3);
	margin: 0;
	padding: 6px 12px 6px 14px;
	border-radius: 12px;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	transition-duration: 125ms;
	border: none;
	outline: none;
}

.ckIpkw {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
	border: none;
	font-weight: 600;
	vertical-align: middle;
}

.efeJnn {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
}

.jDZnZF {
	height: 20px;
	width: 20px;
}

.efeJnn {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
}

.fjCwhV {
	padding-top: 1px;
	color: var(--text-3);
}

.cATJqe {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	border: none;
	text-align: left;
}

.jDpEBx {
	display: flex;
	gap: 8px;
	height: 40px;
}

.yEGMT {
	margin-left: 8px;
	width: 100%;
}

.jQnlQZ {
	display: flex;
	flex: 1 1 0%;
}

.frDKYg {
	display: flex;
	flex-direction: column;
	max-width: 1200px;
	background-color: var(--surface-2);
	box-shadow: var(--box-shadow);
	margin-left: auto;
	margin-right: auto;
	border-radius: 12px;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	border: 1px solid var(--border);
}

/* Token name */
.hJyIyF {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	color: var(--text-1);
}

.fLXlBW {
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding: 0 8px;
	gap: 8px;
}

#hwCfxy {
	padding-right: 8px;
}

#volume-celll {
	padding-right: 8px;
}

.knzTRi {
	-webkit-box-pack: end;
	justify-content: flex-end;
	min-width: 80px;
	user-select: none;
	transition: background-color 250ms ease 0s;
}

.jxjqhR {
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	display: flex;
	gap: 4px;
	-webkit-box-pack: end;
	justify-content: flex-end;
	width: 100%;
}

.bwVaNf {
	color: var(--text-3);
	min-width: 32px;
	font-size: 14px;
}

.cSretk {
	display: inline-block;
	height: inherit;
}

.bcYQwk {
	margin-left: 2px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	cursor: help;
}

.NpKpm {
	padding: 0 24px;
	min-width: 120px;
}

.ejgNi {
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: 100%;
	width: 100%;
	color: var(--text-1);
}

.kiPA-dv {
	text-decoration: none;
}

.cQmpaP:hover {
	background-color: var(--surface-3);
}

.cQmpaP {
	background-color: transparent;
	display: grid;
	font-size: 16px;
	grid-template-columns: 1fr 7fr 4fr 4fr 4fr 4fr 5fr;
	line-height: 24px;
	max-width: 1200px;
	min-width: 390px;
	height: 72px;
	padding: 0px 12px;
	transition: background-color 125ms ease 0s;
	width: 100%;
}

.jqxpYK {
	gap: 8px;
	max-width: 100%;
}

.bwVixy {
	opacity: 1;
	transition: opacity 250ms ease-in 0s;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.bvHTKj {
	display: flex;
	text-decoration: none;
	color: var(--text-1);
	align-items: center;
	cursor: pointer;
}

.gKCxsP {
	gap: 8px;
	line-height: 24px;
	font-size: 16px;
	max-width: inherit;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dQscKx {
	-webkit-box-pack: end;
	justify-content: flex-end;
	min-width: 80px;
	user-select: unset;
	transition: background-color 250ms ease 0s;
}

.igka-dA {
	padding-right: 8px;
}

.fLOBMM {
	padding-right: 8px;
}

.jRVRlR {
	color: var(--text-3);
	text-transform: uppercase;
}

.fLGPoq {
	padding-right: 8px;
}

.gEaRbj {
	padding-right: 8px;
}

.token-symbol-mobile {
	display: block;
}

/* Media query */
@media only screen and (width <= 1200px) {
	/* header row  */
	.fTDqns {
		grid-template-columns: 1fr 6.5fr 4.5fr 4.5fr 4.5fr 4.5fr 1.7fr;
	}

	.cQmpaP {
		grid-template-columns: 1fr 6.5fr 4.5fr 4.5fr 4.5fr 4.5fr 1.7fr;
	}

	.NpKpm {
		display: none;
	}
}

@media only screen and (width <= 840px) {
	/* header row  */
	.fTDqns {
		grid-template-columns: 1fr 7.5fr 4.5fr 4.5fr 4.5fr 1.7fr;
	}

	#volume-celll {
		display: none;
	}

	/* #cJaBDZ {
        display: none;
    } */

	.cQmpaP {
		grid-template-columns: 1fr 7.5fr 4.5fr 4.5fr 4.5fr 1.7fr;
	}

	.gEaRbj {
		display: none;
	}
}

@media only screen and (width <= 768px) {
	.hJyIyF {
		justify-content: left;
	}

	.token-symbol-mobile {
		font-size: 12px;
	}
}

@media only screen and (width > 425px) {
	.hide-above-425 {
		display: none;
	}

	.show-above-425 {
		display: unset;
	}
}

@media only screen and (width > 720px) {
	.hide-above-720 {
		display: none;
	}

	.show-above-720 {
		display: unset;
	}
}

@media only screen and (width > 375px) {
	.hide-above-375 {
		display: none;
	}

	.show-above-375 {
		display: unset;
	}
}

@media only screen and (width <= 320px) {
	.font12-below-320 {
		font-size: 12px !important;
	}

	.hide-below-320 {
		display: none !important;
	}

	.show-below-320 {
		display: unset;
	}
}

@media only screen and (width <= 375px) {
	.hide-below-375 {
		display: none !important;
	}

	.show-below-375 {
		display: unset;
	}
}

@media only screen and (width <= 425px) {
	.hide-below-425 {
		display: none !important;
	}

	.show-below-425 {
		display: unset;
	}
}

@media only screen and (width <= 720px) {
	.hide-below-720 {
		display: none;
	}

	.show-below-720 {
		display: unset;
	}

	.token-input {
		width: 100%;
	}

	.filter-wrapper {
		flex-direction: column;
		gap: 8px;
	}

	.filter-wrapper .filter-dropdowns {
		order: 2;
	}

	#tokenInputWrapper {
		margin: 0;
		order: 1;
	}

	/* header row  */
	.fTDqns {
		grid-template-columns: 1fr 10fr 5fr 5fr 1.2fr;
	}

	#hwCfxy {
		display: none;
	}

	.cQmpaP {
		grid-template-columns: 1fr 10fr 5fr 5fr 1.2fr;
	}

	.fLGPoq {
		display: none;
	}
}

@media (width > 540px) {
	.hide-above-540 {
		display: none;
	}
}

@media (width <= 540px) {
	/* header row  */
	.show-below-540 {
		display: block;
	}

	#giptel {
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.bwVaNf {
		display: none;
	}

	.fTDqns {
		grid-template-columns: 2fr 3fr;
		min-width: unset;
	}

	/* cell */
	.fLOBMM {
		display: none;
	}

	.cQmpaP {
		grid-template-columns: 2fr 3fr;
		min-width: unset;
	}

	.cQmpaP:last-of-type {
		border-bottom: none;
	}
}
