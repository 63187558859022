.lcjkzD {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 48px;
	max-width: 1440px;
	margin: 0 auto;
}

.jdWlrh {
	display: flex;
	flex-direction: column;
}

.dNOeBf {
	/* display: none; */
}

.dIIePS {
	width: 120px;
	height: 120px;
	opacity: 0.7;

	/* display: none; */
}

.eVPHHV {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	gap: 20px;
	margin: 20px 0 0;
}

.fCbTAS {
	display: flex;
}

.kXoVHj {
	font-size: 16px;
	line-height: 20px;
	margin: 1rem 0 0;
	color: var(----text-color);
}

.footer-icon {
	font-size: 32px;
	color: var(----text-color);
	opacity: 1;
}

.kzmIWZ {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px;
}

.koQguvx {
	color: var(--text-3);
}

.dbjOeL {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 200px;
	margin: 20px 0 0;
	color: var(--text-1);
}

.kseCsL {
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
}

.etpyAT {
	font-size: 16px;
	line-height: 20px;
	color: var(--text-3);
}

.inbLGK {
	font-size: 16px;
	line-height: 20px;
	color: var(--text-3);
}

.jnMVFj {
	text-decoration: none;
	cursor: pointer;
	transition-duration: 125ms;
	color: var(--primary);
	stroke: var(--primary);
	font-weight: 500;
}

.gzPmPD {
	height: 32px;
	width: 32px;
	fill: var(--text-3);
	opacity: 1;
}

.dDVVbm {
	height: 32px;
	width: 32px;
	fill: var(--text-3);
	opacity: 1;
}

.jRgqAR {
	height: 32px;
	width: 32px;
	fill: var(--text-3);
	opacity: 1;
}

.budeec {
	font-size: 16px;
	line-height: 20px;
	margin: 1rem 0 0;
	color: var(--text-3);
}

.cSdhBE {
	display: flex;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
}

/* Media QUery */
@media screen and (width >= 1024px) {
	.lcjkzD {
		flex-direction: row;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.dNOeBf {
		display: flex;
	}

	.dIIePS {
		display: block;
	}

	.iGtTgy {
		display: none;
	}
}

@media screen and (width < 1024px) {
	.dNOeBf {
		display: none;
	}
}

@media screen and (width >= 1280px) {
	.kzmIWZ {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 24px;
	}

	.dbjOeL {
		margin: 0;
	}
}
