.button-container {
	justify-content: center;
	display: flex;
	flex-flow: row;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 9999px;
	border: none;
	padding: 0;
	height: 40px;
	transition: color 125ms ease-in 0s;
	overflow: hidden;
	white-space: nowrap;
}

.button-content {
	background-color: transparent;
	border: none;
	font-weight: 600;
	font-size: 14px;
	padding: 6px 16px;
	color: inherit;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.primary-button-container {
	background: var(--primary-gradient);
	color: var(--white);
}

.secondary-button-container {
	background-color: var(--primary-accent);
	color: var(--primary);
	border: 1px solid var(--primary);
}

.tertiary-button-container {
	background-color: transparent;
	color: var(--text-1);
}
