.position-item:hover {
	background-color: var(--surface-3);
}

.container-pool {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	padding: 72px 0 5rem;
	-webkit-box-align: center;
	align-items: center;
	flex: 1 1 0%;
}

.pools-container {
	padding: 68px 8px 0;
	max-width: 870px;
	width: 100%;
}

#poolsContainer {
	display: grid;
	grid-auto-rows: auto;
}

.pools-container .pool-wrapper {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 24px;
	justify-items: center;
	margin-bottom: 1.4rem;
}

.pools-content {
	display: grid;
	grid-auto-rows: auto;
	row-gap: 24px;
}

.pools-head-content {
	width: 100%;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding: 0;
}

#poolsHead {
	-webkit-box-pack: justify;
	justify-content: space-between;
	color: var(--text-3);
}

.pools-left {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 36px;
	color: var(--text-1);
}

.pool-right {
	width: 100%;
	display: flex;
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.pool-right.right {
	width: fit-content;
	color: var(--text-3);
	margin: 0;
	min-width: 0;
	box-sizing: border-box;
}

.more-drop {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	position: relative;
	border: none;
	text-align: left;
}

.more-btn {
	border-radius: 12px;
	flex: 1 1 auto;
	padding: 6px 8px;
	width: 100%;
	background-color: var(--surface-1);
}

.btn-more {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	appearance: none;
	margin-right: 8px;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	padding: 8px 16px;
	color: white;
	border: 0;
	border-radius: 4px;
}

.more-text {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 16px;
}

.hPbqJv {
	border-radius: 12px;
	font-size: 16px;
	padding: 6px 8px;
	width: fit-content;
}

/* Connect WALLET CARD */
.pools-connect-wallet-card {
	background-color: var(--surface-2);
	border: 1px solid var(--border);
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	box-shadow: var(--box-shadow);
	border: 1px solid var(--border);
	overflow: hidden;
	margin-bottom: 1.4rem;
}

.pools-connect-wallet-card-wrapper {
	-webkit-box-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	margin: auto;
	padding: 0 16px;
	min-height: 25vh;
}

.pools-connect-wallet-card-wrapper .inbox {
	font-size: 45px;
	color: var(--text-1);
}

.css-hzsvkq {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
}

.css-hzsvkq .inbox {
	width: 48px;
	height: 48px;
	margin-bottom: 0.5rem;
}

.pools-connect-wallet-btn {
	padding: 16px;
	width: 100%;
	font-weight: 500;
	text-align: center;
	border-radius: 20px;
	outline: none;
	border: 1px solid transparent;
	color: var(--text-1);
	text-decoration: none;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	will-change: transform;
	transition: transform 450ms ease 0s;
	transform: perspective(1px) translateZ(0);
}

.pool-btn {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	appearance: none;
	display: inline-block;
	text-align: center;
	line-height: inherit;
	text-decoration: none;
	font-size: inherit;
	padding: 8px 16px;
	color: white;
	border: 0;

	/* border-radius: 4px; */
	border-radius: 20px;
}

.gcNhTs {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	opacity: 0.8;
}

.byeFaZ {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	opacity: 0.8;
}

.koQguv {
	text-decoration: none;
	cursor: pointer;
	transition-duration: 125ms;
	color: var(--primary);
	stroke: var(--primary);
	font-weight: 500;
}

.dzTOMd {
	grid-template-columns: 1fr;
	width: 100%;
	gap: 8px;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.jdTKGL {
	display: grid;
	grid-auto-rows: auto;
}

.cHXTrK {
	-webkit-box-align: center;
	align-items: center;
	display: flex;
	font-weight: 400;
	font-size: 16px;
}

.css-1lohbqv {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 600;
}

.linking {
	color: var(--text-1);
}

.css-1jljtub {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	font-weight: 400;
	font-size: 16px;
	color: var(--text-1);
}

.pool-button {
	min-width: 150px;
}

@media screen and (width < 425px) {
	.pools-head-content {
		display: flex;
		flex-direction: column;
		align-items: start;
	}

	.pool-right {
		width: 100% !important;
	}

	.pool-button {
		flex: 1;
		margin-top: 8px;
	}
}
