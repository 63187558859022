* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	font-family: Ubuntu, sans-serif;
	overflow-x: hidden !important;
	background: var(--body-bg);
}

a {
	text-decoration: none;
	color: var(--primary);
	transition: color 0.3s ease;
}

a:hover {
	color: var(--primary);
}

ul {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.dark-theme {
	position: relative;
	width: 100vw;
	height: 100vh;
}

.dark-theme::before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	width: 200vw;
	height: 200vh;
	transform: translate(-50vw, -100vh);
	z-index: 1;
}

.backdrop {
	position: absolute;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 550px;
	background: linear-gradient(transparent 0%, var(--surface-1) 45%);

	/* background: linear-gradient(rgba(8, 10, 24, 0) 0%, rgb(8, 10, 24) 45%); */
	z-index: 110;
	pointer-events: none;
	height: calc(100vh - 52px);
	overflow: hidden;
}

._2IvMF {
	background-color: transparent !important;
}

._11RoI {
	background-color: transparent !important;
}

@media screen and (width >= 768px) {
	.backdrop {
		height: 100vh;
	}
}

.App {
	background: var(--body-bg);
}

* {
	scrollbar-width: thin;
	scrollbar-color: var(--text-3) transparent;
}

*::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background: var(--primary-gradient);
	border-radius: 20px;
}

::-webkit-scrollbar-corner {
	background: rgb(0 0 0 / 0%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

input[type='range'] {
	height: 36px;
	background-color: transparent;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
}

input[type='range']:focus {
	outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: var(--primary-accent);
	border-radius: 4px;
}

input[type='range']::-webkit-slider-thumb {
	box-shadow: 1px 1px 5px var(--shadow);
	border: 0px solid var(--primary);
	height: 25px;
	width: 25px;
	border-radius: 25px;
	background: var(--primary);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -13px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	background: var(--primary-accent);
}

input[type='range']::-moz-range-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: var(--primary-accent);
	border-radius: 4px;
}

input[type='range']::-moz-range-thumb {
	box-shadow: 1px 1px 5px var(--shadow);
	border: 0px solid var(--primary);
	height: 30px;
	width: 30px;
	border-radius: 30px;
	background: var(--primary);
	cursor: pointer;
}

input[type='range']::-ms-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

input[type='range']::-ms-fill-lower {
	background: var(--primary-accent);
	border-radius: 8px;
}

input[type='range']::-ms-fill-upper {
	background: var(--primary-accent);
	border-radius: 8px;
}

input[type='range']::-ms-thumb {
	box-shadow: 1px 1px 5px var(--shadow);
	margin-top: 1px;
	border: 0px solid var(--primary-accent);
	height: 30px;
	width: 30px;
	border-radius: 30px;
	background: var(--primary-accent);
	cursor: pointer;
}

input[type='range']:focus::-ms-fill-lower {
	background: var(--primary-accent);
}

input[type='range']:focus::-ms-fill-upper {
	background: var(--primary-accent);
}

.Toastify__toast {
	background-color: var(--surface-2);
	color: var(--text-1);
}

.Toastify__close-button {
	color: var(--text-1) !important;
}
